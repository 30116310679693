import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";
import { InvoiceData } from "../../../types/forwarding/tradingStatement";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../../components/QueryResponseHandler";

import {
  ACCEPT_EXPORT_SHIPMENT_REQ,
  ACCEPT_EXPORT_SHIPMENT_RES,
  CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ,
  CHANGE_ADMIN_BID_CONSOLIDATION_INFO_RES,
  CHANGE_ADMIN_BID_CONSOLIDATION_ITEM_REQ,
  CHANGE_ADMIN_BID_CONSOLIDATION_ITEM_RES,
  CHANGE_ADMIN_BID_REQ,
  CHANGE_ADMIN_BID_RES,
  CHANGE_BID_STATUS_TO_WAITING_FOR_EXPORTER_INO_REQ,
  CHANGE_BID_STATUS_TO_WAITING_FOR_EXPORTER_INO_RES,
  CHANGE_EXPORT_USER_QUOTATION_REQ,
  CHANGE_EXPORT_USER_QUOTATION_REQ_PATH_PARAMS,
  CHANGE_EXPORT_USER_QUOTATION_RES,
  CHANGE_SHIPMENT_EXPORTER_INFO_REQ,
  CHANGE_SHIPMENT_EXPORTER_INFO_RES,
  CHANGE_USER_QUOTATION_REQ,
  CHANGE_USER_QUOTATION_REQ_PATH_PARAMS,
  CHANGE_USER_QUOTATION_RES,
  CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_REQ,
  CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_REQ_PATH_PARAMS,
  CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_RES,
  CHECK_PRICE_RESET_REQ,
  CHECK_PRICE_RESET_RES,
  CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_REQ,
  CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_REQ_PATH_PARAMS,
  CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_RES,
  CONFIRM_BID_QUOTE_REQ,
  CONFIRM_BID_QUOTE_REQ_PATH_PARAMS,
  CONFIRM_BID_QUOTE_RES,
  COPY_PARTNER_QUOTE_REQ,
  COPY_PARTNER_QUOTE_RES,
  COPY_SHIPMENT_FOR_EXPORT_REQ,
  COPY_SHIPMENT_FOR_EXPORT_REQ_PATH_PARAMS,
  COPY_SHIPMENT_FOR_EXPORT_RES,
  COPY_SHIPMENT_FOR_IMPORT_REQ,
  COPY_SHIPMENT_FOR_IMPORT_REQ_PATH_PARAMS,
  COPY_SHIPMENT_FOR_IMPORT_RES,
  DELETE_ADMIN_BID_LIST_REQ,
  DELETE_ADMIN_BID_LIST_REQ_PATH_PARAMS,
  DELETE_ADMIN_BID_LIST_RES,
  DOWNLOAD_BID_QUOTATION_REQ,
  DOWNLOAD_BID_QUOTATION_RES,
  EDIT_BID_QUOTE_DRAFT_REQ,
  EDIT_BID_QUOTE_DRAFT_REQ_PATH_PARAMS,
  EDIT_BID_QUOTE_DRAFT_RES,
  EDIT_QUOTATION_FORWARDER_REQ,
  EDIT_QUOTATION_FORWARDER_REQ_PATH_PARAMS,
  EDIT_QUOTATION_FORWARDER_RES,
  GET_ADMIN_BID_APPLY_FARES_REQ,
  GET_ADMIN_BID_APPLY_FARES_RES,
  GET_ADMIN_BID_APPLY_ITEMS_REQ,
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_REQ,
  GET_ADMIN_BID_DETAIL_RES,
  GET_ADMIN_BID_LIST_REQ,
  GET_ADMIN_BID_LIST_RES,
  GET_ADMIN_BID_PARTNER_QUOTE_LIST_REQ,
  GET_ADMIN_BID_PARTNER_QUOTE_LIST_RES,
  GET_ADMIN_BID_PARTNER_QUOTE_REQ,
  GET_ADMIN_BID_PARTNER_QUOTE_RES,
  GET_SHIPMENT_USER_COMPANY_NAME_LIST_RES,
  REQUEST_EXPORTER_INFO_REQ,
  REQUEST_EXPORTER_INFO_REQ_PATH_PARAMS,
  REQUEST_EXPORTER_INFO_RES,
  REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ,
  REQUEST_PARTNER_EMAIL_FOR_IMPORT_RES,
  REQUEST_PARTNER_QUOTE_FOR_EXPORT_REQ,
  REQUEST_PARTNER_QUOTE_FOR_EXPORT_RES,
  REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ,
  REQUEST_PARTNER_QUOTE_FOR_IMPORT_RES,
  RESET_ADMIN_BID_PARTNER_QUOTE_REQ,
  RESET_ADMIN_BID_PARTNER_QUOTE_RES,
  UPDATE_BID_PARTNER_EMAIL_COMMENT_REQ,
  UPDATE_BID_PARTNER_EMAIL_COMMENT_RES,
  UPDATE_BID_PARTNER_REQ,
  UPDATE_BID_PARTNER_RES,
  UPDATE_BID_QUOTE_DRAFT_REQ,
  UPDATE_BID_QUOTE_DRAFT_RES,
  UPDATE_BID_SCHEDULE_REQ,
  UPDATE_BID_SCHEDULE_REQ_PATH_PARAMS,
  UPDATE_BID_SCHEDULE_RES,
  UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ,
  UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ_PATH_PARAMS,
  UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_RES,
  UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ,
  UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ_PATH_PARAMS,
  UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_RES,
  UPDATE_SHIPMENT_ITEM_CARGO_REQ,
  UPDATE_SHIPMENT_ITEM_CARGO_REQ_PATH_PARAMS,
  UPDATE_SHIPMENT_ITEM_CARGO_RES,
  UPDATE_SHIPMENT_USER_COMPANY_NAME_REQ,
  UPDATE_SHIPMENT_USER_COMPANY_NAME_REQ_PATH_PARAMS,
} from "../../../api-interfaces/shipda-api/admin/adminBid";

export const ADMIN_BID_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_BID_QUERY" }] as const,

  getForwardingManagerHistory: ({ teamId }: { teamId: number }) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        teamId,
        entity: "getForwardingManagerHistory",
      },
    ] as const,

  getAdminInvoice: ({ invoiceId }: { invoiceId: number | undefined }) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        invoiceId,
        entity: "adminInvoice",
      },
    ] as const,

  getAdminBidList: (params: GET_ADMIN_BID_LIST_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminBidList",
      },
    ] as const,

  getAdminBidDetail: (params: GET_ADMIN_BID_DETAIL_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminBidDetail",
      },
    ] as const,

  getAdminBidPartnerQuote: (params: GET_ADMIN_BID_PARTNER_QUOTE_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminBidPartnerQuote",
      },
    ] as const,

  getAdminBidPartnerQuoteList: (params: GET_ADMIN_BID_PARTNER_QUOTE_LIST_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminBidPartnerQuoteList",
      },
    ] as const,

  getAdminBidApplyItems: (params: GET_ADMIN_BID_APPLY_ITEMS_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminBidApplyItems",
      },
    ] as const,

  getAdminBidApplyFares: (params: GET_ADMIN_BID_APPLY_FARES_REQ) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminBidApplyFares",
      },
    ] as const,

  getShipmentUserCompanyNameList: ({ userId }: { userId: number }) =>
    [
      {
        ...ADMIN_BID_QUERY_KEY_GEN.all()[0],
        userId,
        entity: "getShipmentUserCompanyNameList",
      },
    ] as const,
};

function useGetForwardingManagerHistory({
  teamId,
  bidId,
}: {
  teamId: number;
  bidId: number;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getForwardingManagerHistory>,
    [{ changedAt: Date; forwardingManagerId: number }]
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getForwardingManagerHistory({ teamId }),
    requestOptions: {
      method: "get",
      path: `/bid/manager/history/${bidId}/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetAdminInvoice({ invoiceId }: { invoiceId: number | undefined }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice>,
    InvoiceData
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({ invoiceId }),
    requestOptions: {
      method: "get",
      path: `/bid/invoice/${invoiceId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    enabled: invoiceId === undefined ? false : true,
  });

  return { ...queryResult };
}

function useGetAdminBidList({
  params,
  onSuccess,
  enabled,
}: {
  params: GET_ADMIN_BID_LIST_REQ;
  onSuccess?: (res: GET_ADMIN_BID_LIST_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidList>,
    GET_ADMIN_BID_LIST_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidList(params),
    requestOptions: {
      method: "get",
      path: `/bid/list/admin`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useDeleteBidList(options?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    DELETE_ADMIN_BID_LIST_REQ,
    DELETE_ADMIN_BID_LIST_RES,
    DELETE_ADMIN_BID_LIST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ bidId }) => `/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo: options?.successModalInfo,
    failureModalInfo: options?.failureModalInfo,
  });

  return { ...mutation };
}

function useGetAdminBidDetail(params: GET_ADMIN_BID_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail>,
    GET_ADMIN_BID_DETAIL_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail(params),
    requestOptions: {
      method: "get",
      path: `/bid/${params.bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

//TODO: 운송정보 변경 시 나오는 에러 세분화 필요
function useChangeBidDetail(bidId: string | number) {
  const mutation = useAppMutation<CHANGE_ADMIN_BID_REQ, CHANGE_ADMIN_BID_RES>({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage(failureInfo) {
        if (failureInfo?.code === 400) {
          return {
            messageType: "titleOnly",
            title:
              failureInfo.error ??
              "에러가 발생했습니다. 개발자에게 문의해주세요.",
          };
        }

        return {
          title: "에러가 발생했습니다. 개발자에게 문의해주세요.",
        };
      },
    },
  });

  return { ...mutation };
}

function useChangeExportBidDetail(bidId: string | number) {
  const mutation = useAppMutation<CHANGE_ADMIN_BID_REQ, CHANGE_ADMIN_BID_RES>({
    requestOptions: {
      method: "put",
      path: `/export/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage(failureInfo) {
        if (failureInfo?.code === 400) {
          return {
            messageType: "titleOnly",
            title:
              failureInfo.error ??
              "에러가 발생했습니다. 개발자에게 문의해주세요.",
          };
        }

        return {
          title: "에러가 발생했습니다. 개발자에게 문의해주세요.",
        };
      },
    },
  });

  return { ...mutation };
}

function useRequestExporterInfo() {
  const mutation = useAppMutation<
    REQUEST_EXPORTER_INFO_REQ,
    REQUEST_EXPORTER_INFO_RES,
    REQUEST_EXPORTER_INFO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ targetId, kind }) =>
        `/exporters/email/incorrect?targetId=${targetId}&kind=${kind}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCheckPriceReset(bidId: number) {
  const mutation = useAppMutation<CHECK_PRICE_RESET_REQ, CHECK_PRICE_RESET_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/bid/${bidId}/checkReset`,
        apiType: "ShipdaAdminDefaultNew",
      },
    }
  );

  return { ...mutation };
}

function useDownloadBidQuotation(bidId: number) {
  const mutation = useAppMutation<
    DOWNLOAD_BID_QUOTATION_REQ,
    DOWNLOAD_BID_QUOTATION_RES
  >({
    requestOptions: {
      method: "get",
      path: `/bid/download/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateBidPartner(bidId: number) {
  const mutation = useAppMutation<
    UPDATE_BID_PARTNER_REQ,
    UPDATE_BID_PARTNER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/partnerManagers`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminBidPartnerQuote(params: GET_ADMIN_BID_PARTNER_QUOTE_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidPartnerQuote>,
    GET_ADMIN_BID_PARTNER_QUOTE_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidPartnerQuote(params),
    requestOptions: {
      method: "get",
      path: `/partner/bid`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useResetPartnerQuote(id: number) {
  const mutation = useAppMutation<
    RESET_ADMIN_BID_PARTNER_QUOTE_REQ,
    RESET_ADMIN_BID_PARTNER_QUOTE_RES
  >({
    requestOptions: {
      method: "delete",
      path: `/partner/requestQuote/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/**
 * 파트너 견적 복사
 * - 수입에서만 사용
 */
function useCopyPartnerQuote() {
  const mutation = useAppMutation<
    COPY_PARTNER_QUOTE_REQ,
    COPY_PARTNER_QUOTE_RES
  >({
    requestOptions: {
      method: "post",
      path: "/partner/requestQuote/copy",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminBidPartnerQuoteList(
  params: GET_ADMIN_BID_PARTNER_QUOTE_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidPartnerQuoteList>,
    GET_ADMIN_BID_PARTNER_QUOTE_LIST_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidPartnerQuoteList(params),
    requestOptions: {
      method: "get",
      path: `/partner/quote`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useChangeAdminBidConsolidationInfo(bidId: number) {
  const mutation = useAppMutation<
    CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ,
    CHANGE_ADMIN_BID_CONSOLIDATION_INFO_RES
  >({
    requestOptions: {
      method: "put",
      path: `/consolidations/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRequestPartnerQuoteForImport(bidId: number) {
  const mutation = useAppMutation<
    REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ,
    REQUEST_PARTNER_QUOTE_FOR_IMPORT_RES
  >({
    requestOptions: {
      method: "post",
      path: `partner/requestQuote`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E116") {
          return {
            messageType: "titleOnly",
            title: "요청을 보낼 대상이 없거나, 이미 요청한 파트너입니다",
          };
        }

        return {
          messageType: "titleOnly",
          title: "요청 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useRequestPartnerQuoteForExport(bidId: number) {
  const mutation = useAppMutation<
    REQUEST_PARTNER_QUOTE_FOR_EXPORT_REQ,
    REQUEST_PARTNER_QUOTE_FOR_EXPORT_RES
  >({
    requestOptions: {
      method: "post",
      path: `/partner/requestQuote/export`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E116") {
          return {
            messageType: "titleOnly",
            title: "요청을 보낼 대상이 없거나, 이미 요청한 파트너입니다",
          };
        }

        return {
          messageType: "titleOnly",
          title: "요청 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useChangeAdminBidConsolidationItem(bidId: number) {
  const mutation = useAppMutation<
    CHANGE_ADMIN_BID_CONSOLIDATION_ITEM_REQ,
    CHANGE_ADMIN_BID_CONSOLIDATION_ITEM_RES
  >({
    requestOptions: {
      method: "put",
      path: `/consolidations/${bidId}/item`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRequestPartnerEmailForImport() {
  const mutation = useAppMutation<
    REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ,
    REQUEST_PARTNER_EMAIL_FOR_IMPORT_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/partner/booking`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.error === "quote not confirmed yet")
          return {
            messageType: "titleOnly",
            title:
              "견적서를 아직 화주가 수락하지 않았기 때문에 파트너에게 컨택할 수 없습니다.",
          };

        return {
          messageType: "titleAndBody",
          title: "파트너 컨택에 실패했습니다.",
          body: "개발자에게 문의해주세요.",
        };
      },
    },
  });

  return { ...mutation };
}

function useGetAdminBidApplyItems({
  enabled,
  ...params
}: GET_ADMIN_BID_APPLY_ITEMS_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidApplyItems>,
    GET_ADMIN_BID_APPLY_ITEMS_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidApplyItems(params),
    requestOptions: {
      method: "get",
      path: `/bid/applyItems`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useGetAdminBidApplyFares(params: GET_ADMIN_BID_APPLY_FARES_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getAdminBidApplyFares>,
    GET_ADMIN_BID_APPLY_FARES_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getAdminBidApplyFares(params),
    requestOptions: {
      method: "get",
      path: `/forwarderfare/v2/minimal/${params.bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useEditQuotationForwarder() {
  const mutation = useAppMutation<
    EDIT_QUOTATION_FORWARDER_REQ,
    EDIT_QUOTATION_FORWARDER_RES,
    EDIT_QUOTATION_FORWARDER_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId, quotationId }) =>
        `/bid/${bidId}/quotationForwarder/${quotationId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCopyShipmentForImport() {
  const mutation = useAppMutation<
    COPY_SHIPMENT_FOR_IMPORT_REQ,
    COPY_SHIPMENT_FOR_IMPORT_RES,
    COPY_SHIPMENT_FOR_IMPORT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/bid/${shipmentId}/copy`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateBidQuoteDraft() {
  const mutation = useAppMutation<
    UPDATE_BID_QUOTE_DRAFT_REQ,
    UPDATE_BID_QUOTE_DRAFT_RES
  >({
    requestOptions: {
      method: "post",
      path: "/bid/quote/draft",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditBidQuoteDraft() {
  const mutation = useAppMutation<
    EDIT_BID_QUOTE_DRAFT_REQ,
    EDIT_BID_QUOTE_DRAFT_RES,
    EDIT_BID_QUOTE_DRAFT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId, quotationId }) => `/bid/${bidId}/quote/${quotationId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useConfirmBidQuote() {
  const mutation = useAppMutation<
    CONFIRM_BID_QUOTE_REQ,
    CONFIRM_BID_QUOTE_RES,
    CONFIRM_BID_QUOTE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId }) => `/bid/${bidId}/quote`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useChangeBidStatusToWaitingForExporterInfo(bidId: number) {
  const mutation = useAppMutation<
    CHANGE_BID_STATUS_TO_WAITING_FOR_EXPORTER_INO_REQ,
    CHANGE_BID_STATUS_TO_WAITING_FOR_EXPORTER_INO_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/accept`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useChangeUserQuotation() {
  const mutation = useAppMutation<
    CHANGE_USER_QUOTATION_REQ,
    CHANGE_USER_QUOTATION_RES,
    CHANGE_USER_QUOTATION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId, id }) => `/bid/${bidId}/quotationUser/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateBidSchedule() {
  const mutation = useAppMutation<
    UPDATE_BID_SCHEDULE_REQ,
    UPDATE_BID_SCHEDULE_RES,
    UPDATE_BID_SCHEDULE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ scheduleId, bidId }) =>
        `/vesselSchedule/${scheduleId}/assign/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateBidPartnerMailComment() {
  const mutation = useAppMutation<
    UPDATE_BID_PARTNER_EMAIL_COMMENT_REQ,
    UPDATE_BID_PARTNER_EMAIL_COMMENT_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/management/bid/pending/send`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/** 수입과 앤드포인트는 다르지만 payload는 동일 같은 타입을 사용 */
function useUpdateExportBidQuoteDraft() {
  const mutation = useAppMutation<
    UPDATE_BID_QUOTE_DRAFT_REQ,
    UPDATE_BID_QUOTE_DRAFT_RES
  >({
    requestOptions: {
      method: "post",
      path: "export/quote/draft",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditExportBidQuoteDraft() {
  const mutation = useAppMutation<
    EDIT_BID_QUOTE_DRAFT_REQ,
    EDIT_BID_QUOTE_DRAFT_RES,
    EDIT_BID_QUOTE_DRAFT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId, quotationId }) =>
        `/export/quote/${bidId}/quote/${quotationId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useConfirmExportBidQuote() {
  const mutation = useAppMutation<
    CONFIRM_BID_QUOTE_REQ,
    CONFIRM_BID_QUOTE_RES,
    CONFIRM_BID_QUOTE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId }) => `export/quote/${bidId}/quote`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useChangeUserExportQuotation() {
  const mutation = useAppMutation<
    CHANGE_EXPORT_USER_QUOTATION_REQ,
    CHANGE_EXPORT_USER_QUOTATION_RES,
    CHANGE_EXPORT_USER_QUOTATION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId, id }) => `/export/quote/${bidId}/quotationUser/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useAcceptExportShipment(bidId: number) {
  const mutation = useAppMutation<
    ACCEPT_EXPORT_SHIPMENT_REQ,
    ACCEPT_EXPORT_SHIPMENT_RES
  >({
    requestOptions: {
      method: "put",
      path: `/export/${bidId}/accept`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage(failureInfo) {
        if (failureInfo?.code === 400) {
          return {
            messageType: "titleOnly",
            title:
              failureInfo.error ??
              "에러가 발생했습니다. 개발자에게 문의해주세요.",
          };
        }

        return {
          title: "에러가 발생했습니다. 개발자에게 문의해주세요.",
        };
      },
    },
  });

  return { ...mutation };
}

function useChangeShipmentExporterInfo() {
  const mutation = useAppMutation<
    CHANGE_SHIPMENT_EXPORTER_INFO_REQ,
    CHANGE_SHIPMENT_EXPORTER_INFO_RES
  >({
    requestOptions: {
      method: "put",
      path: `/exporters`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCopyShipmentForExport() {
  const mutation = useAppMutation<
    COPY_SHIPMENT_FOR_EXPORT_REQ,
    COPY_SHIPMENT_FOR_EXPORT_RES,
    COPY_SHIPMENT_FOR_EXPORT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/export/${shipmentId}/copy`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/**
 * 화물정보를 수정
 * - 화물정보를 수정한다고 해서견적이 자동으로 재계산되지 않음에 유의
 */
function useUpdateShipmentItemCargo() {
  const mutation = useAppMutation<
    UPDATE_SHIPMENT_ITEM_CARGO_REQ,
    UPDATE_SHIPMENT_ITEM_CARGO_RES,
    UPDATE_SHIPMENT_ITEM_CARGO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/bid/v2/${shipmentId}/item`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/**
 * 화물정보를 변경할때, 견적도 재계산/리셋도 해야하는 건지 확인
 */
function useCheckNeedToChangeQuotationByUpdateShipmentItemCargo() {
  const mutation = useAppMutation<
    CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_REQ,
    CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_RES,
    CHECK_NEED_TO_CHANGE_QUOTATION_BY_UPDATE_SHIPMENT_ITEM_CARGO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/bid/${shipmentId}/checkReset/item`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCheckShipmentResetByInfoUpdate() {
  const mutation = useAppMutation<
    CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_REQ,
    CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_RES,
    CHECK_SHIPMENT_RESET_BY_INFO_CHANGE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/bid/${shipmentId}/checkReset`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useImportShipmentInfoUpdateWithoutReCalCulation() {
  const mutation = useAppMutation<
    UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ,
    UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_RES,
    UPDATE_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/bid/v2/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useExportShipmentInfoUpdateWithoutReCalCulation() {
  const mutation = useAppMutation<
    UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ,
    UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_RES,
    UPDATE_EXPORT_SHIPMENT_INFO_WITHOUT_RECALCULATION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/export/v2/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetShipmentUserCompanyNameList({ userId }: { userId: number }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_QUERY_KEY_GEN.getShipmentUserCompanyNameList>,
    GET_SHIPMENT_USER_COMPANY_NAME_LIST_RES
  >({
    queryKey: ADMIN_BID_QUERY_KEY_GEN.getShipmentUserCompanyNameList({
      userId,
    }),
    requestOptions: {
      method: "get",
      path: `/team/user/${userId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useShipmentUserCompanyNameUpdate() {
  const mutation = useAppMutation<
    UPDATE_SHIPMENT_USER_COMPANY_NAME_REQ,
    unknown,
    UPDATE_SHIPMENT_USER_COMPANY_NAME_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/bid/${shipmentId}/migrate`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const ADMIN_BID_QUERY = {
  useGetForwardingManagerHistory,
  useGetAdminInvoice,
  useGetAdminBidList,
  useDeleteBidList,
  useGetAdminBidDetail,
  useChangeBidDetail,
  useChangeExportBidDetail,
  useRequestExporterInfo,
  useCheckPriceReset,
  useDownloadBidQuotation,
  useUpdateBidPartner,
  useGetAdminBidPartnerQuote,
  useResetPartnerQuote,
  useGetAdminBidPartnerQuoteList,
  useChangeAdminBidConsolidationInfo,
  useChangeAdminBidConsolidationItem,
  useRequestPartnerEmailForImport,
  useGetAdminBidApplyItems,
  useGetAdminBidApplyFares,
  useEditQuotationForwarder,
  useCopyShipmentForImport,
  useCopyShipmentForExport,
  useUpdateBidQuoteDraft,
  useEditBidQuoteDraft,
  useConfirmBidQuote,
  useCopyPartnerQuote,
  useChangeBidStatusToWaitingForExporterInfo,
  useChangeUserQuotation,
  useUpdateBidSchedule,
  useUpdateBidPartnerMailComment,
  useChangeUserExportQuotation,
  useUpdateExportBidQuoteDraft,
  useConfirmExportBidQuote,
  useEditExportBidQuoteDraft,
  useAcceptExportShipment,
  useRequestPartnerQuoteForImport,
  useRequestPartnerQuoteForExport,
  useChangeShipmentExporterInfo,
  useUpdateShipmentItemCargo,
  useCheckNeedToChangeQuotationByUpdateShipmentItemCargo,
  useCheckShipmentResetByInfoUpdate,
  useImportShipmentInfoUpdateWithoutReCalCulation,
  useExportShipmentInfoUpdateWithoutReCalCulation,
  useGetShipmentUserCompanyNameList,
  useShipmentUserCompanyNameUpdate,
};

export default ADMIN_BID_QUERY;
